<template>
  <el-main>
    <page-title show-back-btn />
    <div class="partition-area">
      <ics-company-info page-title="资金方信息" company-type="capital" :info-detail="capitalDetail" :loading-detail="loading" @submit="submitForms" />
    </div>
  </el-main>
</template>

<script>
import IcsCompanyInfo from '@/components/company-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  components: { IcsCompanyInfo },
  mixins: [routeEnterMixin],
  data () {
    return {
      capCode: this.$route.query.capCode,
      capitalDetail: {}
    }
  },
  created () {
    if (this.capCode) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.company.capital.getCapDetail({ capCode: this.capCode }).then(result => {
        const data = result.data.data || {}
        let regAddr = []
        let workAddr = []
        if (data.firm.regAddr) {
          regAddr = data.firm.regAddr.split(',')
          data.firm.usEnterpriseAddress = [regAddr[0], regAddr[1], regAddr[2], data.firm.regAddrDetail]
        }
        if (data.firm.workAddr) {
          workAddr = data.firm.workAddr.split(',')
          data.firm.dsEnterpriseAddress = [workAddr[0], workAddr[1], workAddr[2], data.firm.workAddrDetail]
        }
        const cityKeys = []
        data.cityList.forEach(item => {
          cityKeys.push(item.areaCode)
        })
        data.firm.cityList = cityKeys
        this.capitalDetail = data.firm || {}
      }).finally(() => {
        this.loading.detail = false
      })
    },
    submitForms (formData, cityList) {
      return new Promise((resolve, reject) => {
        this.loading.submit = true
        const data = formData || {}
        let regAddr = ''
        let regAddrDetail = ''
        let workAddr = ''
        let workAddrDetail = ''
        if (data.usEnterpriseAddress !== undefined && data.usEnterpriseAddress.length > 0) {
          const regAddr1 = [data.usEnterpriseAddress[0], data.usEnterpriseAddress[1], data.usEnterpriseAddress[2]]
          regAddr = regAddr1.join(',')
          regAddrDetail = data.usEnterpriseAddress[3]
        }
        if (data.dsEnterpriseAddress !== undefined && data.dsEnterpriseAddress.length > 0) {
          const workAddr1 = [data.dsEnterpriseAddress[0], data.dsEnterpriseAddress[1], data.dsEnterpriseAddress[2]]
          workAddr = workAddr1.join(',')
          workAddrDetail = data.dsEnterpriseAddress[3]
        }
        if (this.capCode) {
          const capId = data.id
          const info = {
            contactName: data.contactName,
            contactPhone: data.contactPhone,
            email: data.mail,
            regAmount: data.regAmount,
            tel: data.tel,
            fax: data.fax,
            id: data.id,
            scope: data.scope,
            legalPerson: data.legalPerson,
            legalIdentity: data.legalIdentity,
            remark: data.remark,
            net: data.net,
            regAddr: regAddr,
            regAddrDetail: regAddrDetail,
            workAddr: workAddr,
            workAddrDetail: workAddrDetail
          }
          this.api.company.capital.capAlt(info).then(result => {
            if (result.data.success === true) {
              this.$message.success('保存成功')
              this.loading.submit = false
              this.api.company.capital.firmCitySave(capId, cityList).then(result => {
                if (result.data.success === true) {
                } else {
                  this.$message.warning('业务覆盖城市错误')
                  this.loading.submit = false
                  return false
                }
              })
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
            resolve(result.data.data)
          }).catch(e => {
            reject(e)
          }).finally(() => {
            this.loading.submit = false
          })
        } else {
          const record = {
            capRel: {},
            firm: {
              companyName: data.companyName,
              socialNo: data.socialNo,
              regAmount: data.regAmount,
              legalPerson: data.legalPerson,
              legalIdentity: data.legalIdentity,
              tel: data.tel,
              email: data.email,
              fax: data.fax,
              contactName: data.contactName,
              contactPhone: data.contactPhone,
              net: data.net,
              regAddr: regAddr,
              regAddrDetail: regAddrDetail,
              workAddr: workAddr,
              workAddrDetail: workAddrDetail,
              scope: data.scope,
              remark: data.remark
            },
            coreCapAmountRelList: [],
            coreCapProdRelList: [],
            cityList: cityList,
            bankInfoList: []
          }
          this.api.company.capital.addCap(record).then(result => {
            if (result.data.success === true) {
              this.$message.success('保存成功')
              this.loading.submit = false
              const data = result.data.data
              this.api.company.capital.firmCitySave(data.firm.id, cityList).then(result => {
                if (result.data.success === true) {
                } else {
                  this.$message.warning('业务覆盖城市错误')
                  this.loading.submit = false
                  return false
                }
              })
              this.$router.back()
            } else {
              this.$message.error(result.data.message)
              this.loading.submit = false
            }
            resolve(result.data.data)
          }).catch(e => {
            reject(e)
          }).finally(() => {
            this.loading.submit = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
